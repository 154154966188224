import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { BehaviorSubject, map, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  IQuery,
  IResponseData,
  IResponsePagination,
  IUser,
  TResponsePagination,
} from 'interfaces';

@Injectable({
  providedIn: 'platform',
})
export class UserService {
  protected readonly http = inject(HttpClient);

  public user!: IUser;
  public readonly user$: BehaviorSubject<IUser | null> =
    new BehaviorSubject<IUser | null>(null);

  constructor() {
    this._me().subscribe((user) => {
      this.user = user;
      this.user$.next(user);
    });
  }

  protected _updateMe(user: IUser) {
    this.user = user;
    this.user$.next(user);

    return user;
  }

  public _me(): Observable<IUser> {
    return this.http
      .get<IResponseData<IUser>>(`@api/users/user/me`)
      .pipe(map((response) => response.data));
  }

  public find(query: IQuery): Observable<TResponsePagination<IUser[]>> {
    const params = new HttpParams({ fromObject: { ...query } });

    return this.http
      .get<IResponsePagination<IUser[]>>(`@api/users`, { params })
      .pipe(map((response) => response.data));
  }

  public updateMeWallet(userDTO: { wallet: string }): Observable<IUser> {
    return this.http
      .patch<IResponseData<IUser>>(`@api/users/user/me/wallet`, userDTO)
      .pipe(
        map((response) => {
          return this._updateMe(response.data);
        }),
      );
  }
}

export const hasAuth: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const userService = inject(UserService);

  return userService._me().pipe(
    map((response) => !!response),
    catchError(() => {
      void router.navigate(['/splash']);

      return of(false);
    }),
  );
};
