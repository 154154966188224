import { ESocialNetworkType } from 'interfaces';

export const shareInSocialUrl = (link: string, social: ESocialNetworkType) => {
  if (social === ESocialNetworkType.telegram) {
    // &text=
    return `https://t.me/share/url?url=${link}`;
  } else if (social === ESocialNetworkType.twitter) {
    // &text=
    return `https://twitter.com/intent/tweet?url=${link}`;
  } else if (social === ESocialNetworkType.facebook) {
    return `https://www.facebook.com/sharer/sharer.php?u=${link}`;
  }

  throw Error();
};
