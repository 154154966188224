import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import {
  IQuery,
  IResponsePagination,
  IUser,
  TResponsePagination,
} from 'interfaces';

@Injectable({
  providedIn: 'root',
})
export class CommunityFriendService {
  protected readonly http = inject(HttpClient);

  public find(query: IQuery): Observable<TResponsePagination<IUser[]>> {
    const params = new HttpParams({ fromObject: { ...query } });

    return this.http
      .get<IResponsePagination<IUser[]>>(`@api/community/friends`, { params })
      .pipe(map((response) => response.data));
  }

  public request(user: IUser) {
    return this.http
      .post<
        IResponsePagination<IUser[]>
      >(`@api/community/friends/friend/request`, { user })
      .pipe(map((response) => response.data));
  }

  public accept(user: IUser) {
    return this.http
      .post<
        IResponsePagination<IUser[]>
      >(`@api/community/friends/friend/accept`, { user })
      .pipe(map((response) => response.data));
  }

  public remove(user: IUser) {
    return this.http
      .post<
        IResponsePagination<IUser[]>
      >(`@api/community/friends/friend/remove`, { user })
      .pipe(map((response) => response.data));
  }
}
