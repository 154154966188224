export enum EItemSampleType {
  WEAPON = 'WEAPON',
  ARMOR = 'ARMOR',
}

export enum EItemSampleWeapon {
  SWORD_ONE_HANDED = 'SWORD_ONE_HANDED',
  SWORD_TWO_HANDED = 'SWORD_TWO_HANDED',
  AXE_ONE_HANDED = 'AXE_ONE_HANDED',
  AXE_TWO_HANDED = 'AXE_TWO_HANDED',
  DAGGER = 'DAGGER',
  DAGGER_DUAL = 'DAGGER_DUAL',
  BOW = 'BOW',
  CROSSBOW = 'CROSSBOW',
  STAFF = 'STAFF',
  WAND = 'WAND',
  SHIELD = 'SHIELD',
  MACE_ONE_HANDED = 'MACE_ONE_HANDED',
  MACE_TWO_HANDED = 'MACE_TWO_HANDED',
}

export enum EItemSampleArmor {
  LIGHT = 'LIGHT',
  MEDIUM = 'MEDIUM',
  HEAVY = 'HEAVY',
}

export type TItemSampleVariety = EItemSampleWeapon | EItemSampleArmor;

export const TItemSampleVariety = [
  ...Object.values(EItemSampleWeapon),
  ...Object.values(EItemSampleArmor),
];

export enum EItemSampleRarity {
  COMMON = 'COMMON',
  UNCOMMON = 'UNCOMMON',
  RARE = 'RARE',
  EPIC = 'EPIC',
  LEGENDARY = 'LEGENDARY',
  MYTHIC = 'MYTHIC',
}

export interface IItemSampleInterface {
  id: string;

  title: string;
  description: string;
  image: string;

  type: EItemSampleType;
  variety: TItemSampleVariety;
  rarity: EItemSampleRarity;
}

export interface IItemSampleWeapon extends IItemSampleInterface {
  type: EItemSampleType.WEAPON;
  variety: EItemSampleWeapon;
}

export interface IItemSampleArmor extends IItemSampleInterface {
  type: EItemSampleType.ARMOR;
  variety: EItemSampleArmor;
}

export type TItemSample = IItemSampleWeapon | IItemSampleArmor;

// export enum EItemSampleType {
//   WEAPON = 'WEAPON',
//   ARMOR = 'ARMOR',
//   ACCESSORY = 'ACCESSORY',    // Аксессуары: кольца, амулеты, пояса
//   POTION = 'POTION',          // Зелья
//   CONSUMABLE = 'CONSUMABLE',  // Расходуемые предметы: еда, бафы и т.д.
//   MISC = 'MISC',              // Прочее: материалы, квестовые предметы
//   TRINKET = 'TRINKET',        // Маленькие магические предметы, брелоки
//   RELIC = 'RELIC',            // Древние артефакты, магические предметы
//   TOOL = 'TOOL',              // Инструменты (например, кирка, алхимические наборы)
// }
//
// export enum EItemSampleWeapon {
//   SWORD_ONE_HANDED = 'SWORD_ONE_HANDED',
//   SWORD_TWO_HANDED = 'SWORD_TWO_HANDED',
//   AXE_ONE_HANDED = 'AXE_ONE_HANDED',
//   AXE_TWO_HANDED = 'AXE_TWO_HANDED',
//   DAGGER = 'DAGGER',
//   DAGGER_DUAL = 'DAGGER_DUAL',
//   BOW = 'BOW',
//   CROSSBOW = 'CROSSBOW',
//   STAFF = 'STAFF',
//   WAND = 'WAND',
//   SHIELD = 'SHIELD',
//   MACE_ONE_HANDED = 'MACE_ONE_HANDED',
//   MACE_TWO_HANDED = 'MACE_TWO_HANDED',
//   SPEAR = 'SPEAR',            // Копьё
//   HALBERD = 'HALBERD',        // Алебарда
//   FLAIL = 'FLAIL',            // Цеп
//   THROWING_KNIFE = 'THROWING_KNIFE',  // Метательный нож
//   THROWING_AXE = 'THROWING_AXE',      // Метательный топор
//   FIST_WEAPON = 'FIST_WEAPON',        // Кастеты, когти
//   POLEARM = 'POLEARM',        // Древковое оружие (глефы и т.д.)
//   WHIP = 'WHIP',              // Кнут
//   MAGIC_ORB = 'MAGIC_ORB',    // Магический орб
// }
//
// export enum EItemSampleArmor {
//   LIGHT = 'LIGHT',            // Лёгкая броня (кожаная, тканевая)
//   MEDIUM = 'MEDIUM',          // Средняя броня (кольчуга)
//   HEAVY = 'HEAVY',            // Тяжёлая броня (латная)
//   SHIELD = 'SHIELD',          // Щит
//   HELMET = 'HELMET',          // Шлем
//   CHESTPLATE = 'CHESTPLATE',  // Нагрудник
//   GAUNTLETS = 'GAUNTLETS',    // Перчатки
//   BOOTS = 'BOOTS',            // Ботинки
//   LEGGINGS = 'LEGGINGS',      // Набедренники
//   PAULDRONS = 'PAULDRONS',    // Наплечники
//   CLOAK = 'CLOAK',            // Плащ
//   BELT = 'BELT',              // Пояс (может относиться к броне)
//   ARMOR_SET = 'ARMOR_SET',    // Комплекты брони
// }
//
// export enum EItemSampleAccessory {
//   RING = 'RING',              // Кольцо
//   AMULET = 'AMULET',          // Амулет
//   BELT = 'BELT',              // Пояс
//   BRACER = 'BRACER',          // Наручи
//   NECKLACE = 'NECKLACE',      // Ожерелье
//   TRINKET = 'TRINKET',        // Маленькие магические предметы
//   EARRING = 'EARRING',        // Серьги
//   CAPE = 'CAPE',              // Накидка (аксессуар, а не броня)
// }
//
// export enum EItemSamplePotion {
//   HEALTH = 'HEALTH',          // Зелье здоровья
//   MANA = 'MANA',              // Зелье маны
//   STAMINA = 'STAMINA',        // Зелье выносливости
//   BUFF = 'BUFF',              // Зелье баффов (увеличение характеристик)
//   DEBUFF = 'DEBUFF',          // Зелье дебаффов (ослабление противников)
//   ANTIDOTE = 'ANTIDOTE',      // Антидоты
// }
//
// export enum EItemSampleMisc {
//   QUEST_ITEM = 'QUEST_ITEM',  // Квестовые предметы
//   MATERIAL = 'MATERIAL',      // Материалы для крафта
//   TREASURE = 'TREASURE',      // Сокровища (например, золото, драгоценности)
//   KEY = 'KEY',                // Ключи
// }
//
// export enum EItemSampleTool {
//   PICKAXE = 'PICKAXE',        // Кирка
//   SHOVEL = 'SHOVEL',          // Лопата
//   FISHING_ROD = 'FISHING_ROD',// Удочка
//   LOCKPICK = 'LOCKPICK',      // Отмычка
// }
