import { AsyncPipe, JsonPipe, NgForOf, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  DestroyRef,
  inject,
  INJECTOR,
  NgModule,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';

import {
  TuiAlertService,
  TuiDialogService,
  TuiDialogOptions,
  TuiButton,
  TuiFallbackSrcPipe,
  TuiIcon,
  TuiLink,
  TuiLoader,
  TuiGroup,
  TuiTextfieldComponent,
  TuiTextfieldDirective,
} from '@taiga-ui/core';
import { TuiAvatar, TuiConfirmService } from '@taiga-ui/kit';
import { PolymorpheusContent } from '@taiga-ui/polymorpheus';


import { fromNano, toNano } from '@ton/core';


import { InViewportAction } from 'ng-in-viewport';

import { BehaviorSubject } from 'rxjs';

import { CommunityFriendService } from 'client/modules/pages/community/modules/friend/friend.service';
import { CommunityReferralService } from 'client/modules/pages/community/modules/referral/referral.service';

import { PaymentService } from 'client/modules/pages/payment/payment.service';
import { UserService } from 'client/modules/user/user.service';

import {
  hasNextPage,
  hasPreviousPage,
  nextPage,
  telegramUserReferralUrl,
} from 'helpers';

import {
  EPaymentStatus,
  EPaymentType,
  IPagination,
  IQuery,
  IUser,
} from 'interfaces';

const modules = [
  AsyncPipe,
  JsonPipe,
  NgForOf,
  NgIf,
  ReactiveFormsModule,
  RouterModule,

  TuiButton,
  TuiFallbackSrcPipe,
  TuiIcon,
  TuiLink,
  TuiLoader,
  TuiGroup,
  TuiTextfieldComponent,
  TuiTextfieldDirective,
  TuiAvatar,
];

@NgModule({
  imports: modules,
  providers: [HttpClient, TuiConfirmService, PaymentService, UserService],
  exports: modules,
})
export class BaseModule {}

@Component({
  selector: 'lc-base-helper',
  standalone: true,
  imports: [],
  providers: [TuiConfirmService, PaymentService],
  template: ``,
})
export class BaseComponent {
  protected readonly injector = inject(INJECTOR);
  protected readonly destroyRef = inject(DestroyRef);

  protected readonly router = inject(Router);

  protected readonly alerts = inject(TuiAlertService);
  protected readonly dialogs = inject(TuiDialogService);
  protected readonly confirm = inject(TuiConfirmService);

  protected readonly communityFriendService = inject(CommunityFriendService);
  protected readonly communityReferralService = inject(
    CommunityReferralService,
  );
  public readonly userService = inject(UserService);
  public readonly paymentService = inject(PaymentService);

  public readonly users$: BehaviorSubject<IUser[]> = new BehaviorSubject<
    IUser[]
  >([]);

  protected query: IQuery = {};
  public pagination: IPagination = {
    page_index: 0,
    page_size: 0,
    count: 0,
  };
  protected loading: boolean = true;

  public readonly hasNextPage = hasNextPage;
  public readonly hasPreviousPage = hasPreviousPage;
  public readonly nextPage = nextPage;
  public readonly telegramUserReferralUrl = telegramUserReferralUrl;

  public fromNano = fromNano;
  public toNano = toNano;

  public readonly EPaymentType = EPaymentType;
  public readonly EPaymentStatus = EPaymentStatus;

  public openDialog<T>(
    content: PolymorpheusContent,
    options?: Partial<TuiDialogOptions<T>>,
  ) {
    this.dialogs
      .open(content, options)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {});
  }

  // Just for extends
  protected find() {}

  handleNextPage(event: InViewportAction): void {
    if (hasNextPage(this.pagination) && event.visible) {
      this.query.page_index = nextPage(this.pagination);

      this.find();
    }
  }
}
