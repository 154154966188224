import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import {
  IPayment,
  IQuery,
  IResponsePagination,
  TResponsePagination,
} from 'interfaces';

@Injectable({
  providedIn: 'platform',
})
export class PaymentService {
  protected readonly http = inject(HttpClient);

  public find(query: IQuery): Observable<TResponsePagination<IPayment[]>> {
    const params = new HttpParams({ fromObject: { ...query } });

    return this.http
      .get<IResponsePagination<IPayment[]>>(`@api/payments`, { params })
      .pipe(map((response) => response.data));
  }

  public createDeposit(payload: { amount: number }): Observable<any> {
    return this.http
      .post<any>(`@api/payments/deposit`, payload)
      .pipe(map((response) => response.data));
  }

  public createWithdrawal(payload: { amount: number }): Observable<any> {
    return this.http
      .post<any>(`@api/payments/withdrawal`, payload)
      .pipe(map((response) => response.data));
  }
}
